@import '@crystal-eyes/constants.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 48px);
  padding: 0 24px;

  &.limitWidth {
    width: calc(100% - 300px);
    padding: 0;
    @media (max-width: 1040px) {
      width: 100%;
      padding: 0;
    }
  }

  min-height: $content-min-height;
  font-family: var(--font-lato);

  :global(.page-heading) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 170px;
    font-size: 1.2rem;
    max-width: 100vw;
    box-sizing: border-box;

    @media (max-width: $main-content-width) {
      text-align: center;
    }

    h1 {
      color: black;
      font-size: 2rem;
    }

    :global(.subtitle) {
      color: #7e7e7e;
      text-wrap: wrap;
      word-wrap: break-word;
    }
  }

  :global(.page-content) {
    width: 100%;
    row-gap: 40px;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > * {
      max-width: 800px;
    }
  }
}
