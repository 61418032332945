@import '@crystal-eyes/constants.scss';

.body {
  font-family: var(--font-lato);
  font-weight: normal;
  -webkit-font-smoothing: antialiased;

  input,
  select {
    font-size: 1rem;
    line-height: 1.4rem;
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
  }

  button {
    font-family: var(--font-lato);
  }

  input {
    font-family: var(--font-lato);
    font-size: 0.875rem;

    &::placeholder {
      font-family: var(--font-lato);
    }
  }

  label {
    font-family: var(--font-lato);
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 700;
    color: $gray-text;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  min-height: $content-min-height;
  font-family: var(--font-lato);

  &.limitWidth {
    width: calc(100% - 300px);
  }

  :global(.page-heading) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 170px;
    font-size: 1.2rem;
    max-width: 100vw;
    box-sizing: border-box;

    @media (max-width: $main-content-width) {
      text-align: center;
    }

    h1 {
      color: black;
      font-size: 2rem;
    }

    :global(.subtitle) {
      color: #7e7e7e;
      text-wrap: wrap;
      word-wrap: break-word;
    }
  }

  :global(.page-content) {
    background-color: #f5f5f5;
    width: 100%;
    min-width: 100vw;
    row-gap: 40px;
    padding: 40px 0;
    box-shadow: 0px -8px 20px rgba(50, 50, 50, 0.05);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > * {
      max-width: 800px;
    }
  }
}
