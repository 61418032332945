@import '@crystal-eyes/constants.scss';

$mobile-width: 900px;

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $crystal-midnight-blue;
  color: white;
  min-height: 533px;

  @media print {
    display: none;
  }

  :global(.sections) {
    display: grid;
    grid-template-columns: 12em 12em 12em 12em;
    justify-content: center;
    align-items: flex-start;
    font-size: 0.9rem;

    @media (max-width: $mobile-width) {
      display: flex;
      flex-direction: column;
    }

    h4 {
      font-weight: 300;
      font-size: 1.1em;
    }

    :global(.section) {
      a {
        color: white;
        text-decoration: none;
        font-size: 0.8em;
      }

      a:hover {
        color: #a2e2fc;
      }
    }
  }

  :global(.links) {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    border-left: 1px solid #a2e2fc;
    padding-left: 1em;
  }

  :global(.social-links) {
    svg {
      width: 1rem;
      height: 1rem;
      margin: 3rem 0.25rem 1rem 0.25rem;
    }
  }

  :global(.legal) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    a {
      color: white;
      text-decoration: none;
      font-size: 0.8em;
    }

    a:hover {
      color: #a2e2fc;
    }

    div:first-child {
      border-left: none;
    }

    div {
      padding: 0 1rem;
      border-left: 1px solid #a2e2fc;
    }
  }
}
