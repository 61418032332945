@import '@crystal-eyes/constants.scss';

body {
  background-color: rgba(44, 167, 228, 0.12);
  background-image: url('/static/share/images/background.svg');
  margin: 0 0;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
  color: $default-heading-color;
  margin-bottom: 0.5rem;
}

a {
  color: $action-blue;
  text-decoration: none;

  &.underline {
    text-decoration: underline;
  }

  &.secondary {
    color: $gray-text;
    text-decoration: underline;
  }

  &:hover {
    color: #01293d;
    text-decoration: underline;
  }
}
